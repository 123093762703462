import React from "react";
import { Box, Stack, Button, Text, Heading } from "@chakra-ui/react";
import Container from "components/ui/Container";
import Seo from "components/Seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Features from "components/Features";
import Reviews from "components/Reviews";
import Partners from "components/Partners";

const AboutUsPage: React.FC = () => {
  return (
    <Box pb={10}>
      <Seo
        title="About Us | Kitchen Interior Design and Rendering Software"
        description="Our powerful kitchen interior design software is designed to deliver stunningly lifelike renderings. our online interior rendering software shows you a realistic view."
        keywords="interior design software online, interior design rendering software, ai interior design, online floor plan design software, cloud-based interior design and rendering software platform, online interior rendering software, kitchen interior design software"
      />

      <Container className="relative">
        <StaticImage
          src="../images/banners/about-us/header.jpg"
          alt="about us banner aihouseindia"
          layout="fullWidth"
        />

        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          background="rgba(0, 0, 0, 0.3)"
          zIndex="4"
        />

        <Text
          as="h1"
          fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
          textAlign="center"
          fontWeight="bold"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="white"
          zIndex="5"
        >
          About Us
        </Text>
      </Container>

      <Container fluid>
        <Stack mt={10} spacing={20} alignItems="center">
          <Stack spacing={6}>
            <Heading
              as="h1"
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
            >
              Professional Interior Design & Rendering Software
            </Heading>
            <Text>
              AiHouse is a user-friendly, intelligent, cloud-based 3D interior
              design visualization and rendering software platform where you can
              quickly create a smart panorama and virtual reality (VR) to showcase
              to end-users/clients. With AiHouse 3D interior and exterior design
              rendering software, you can provide users with an immersive
              virtual reality environment to maximize design impact.
            </Text>

            <Text>
              {/* Aihouse India offers FREE TRAINING so that users may appreciate
              the deep,  */}
              This Ai Interior Rendering Software positive impact the platform will have on design
              capabilities and customer satisfaction. Aihouse India operates
              under the aegis of Mitha interior design and construction pvt ltd,
              based in Gurgaon, HR. India.
            </Text>
          </Stack>

          <Features />

          <Stack spacing={6}>
            <Heading
              as="h2"
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
            >
              Why AiHouse
            </Heading>

            <Text>
              With our online floor plan design software, whether you want to
              design floor plans, interior layouts, furniture, fixtures, and
              decorative elements, with the help of this powerful kitchen interior design software
              all that can now be done in a matter of
              minutes in an intuitive, seamless process. At the starting point,
              you have 4 ways to design entire complete floorplans – select from
              library, free drawing mode, importing/uploading from CAD software
              files & PNG/JPEG images.
            </Text>

            <Text>
              We understand that interior design is all about creativity, and
              that's why our software is designed to give you complete freedom
              to explore your ideas. With our software, you can easily
              experiment with different colors, textures, and materials to
              create the perfect design that meets your client's needs.
            </Text>

            <Text color="orange.500" fontSize="xl">
              What one took days to create, develop and integrate across
              multiple software can now be seamlessly executed in one
              integrated, collaborative platform in a matter of hours.
            </Text>
          </Stack>

          <Stack spacing={6}>
            <Heading
              as="h2"
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
            >
              Smart Features
            </Heading>

            <Text>
              <Text as="span" fontWeight="bold">
                Marketing And Presentation:
              </Text>{" "}
              720 Panorama can provide users with an immersive virtual reality
              environment to maximize design impact. With cloud rendering
              servers, takes only 3 minutes to render a photo-realistic 3D
              perspective while being able to render multiple perspectives
              concurrently.
            </Text>

            <Text>
              <Text as="span" fontWeight="bold">
                Smart Lighting Function:
              </Text>{" "}
              The renderings automatically adjust the placement and intensity of
              the lighting, shadows, and chamfering.
            </Text>

            <Text>
              <Text as="span" fontWeight="bold">
                Artificial Intelligence (AI) based Recommendation for Models and
                Materials:
              </Text>{" "}
              With over 80 million (and growing…) 3D models and materials;
              AiHouse is able to recommend similar models and textures based on
              real-life pictures and/or photos.
            </Text>

            <Text>
              <Text as="span" fontWeight="bold">
                Intelligent 2D-3D Generation:
              </Text>{" "}
              Ability to recognize 2D floorplan images and automatically convert
              them into 3D models.
            </Text>

            <Text>
              <Text as="span" fontWeight="bold">
                Smart Layout With Multiple Styles For the Whole House:
              </Text>{" "}
              Using artificial intelligence (AI), AiHouse is able to determine
              the buildup of an interior environment and provide users with
              furniture recommendations based on their desired interior concept.
            </Text>
          </Stack>

          <StaticImage
            src="../images/banners/ai-house-package.png"
            alt="aihouse package image box"
          />

          <Button colorScheme="orange" size="lg" as={Link} to="/contact-us/">
            Contact Us
          </Button>

          <Reviews />

          <Partners />
        </Stack>
      </Container>
    </Box>
  );
};

export default AboutUsPage;
